import React from 'react';
import LandingLayout from '../layout/Home/Landing';

const Home = (props) => {
  return (
<LandingLayout>
  
</LandingLayout>
  );
};

export default Home;