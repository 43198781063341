import React, { Suspense } from 'react';
import AdminRoutes from './auth/admin';
import NotAuthRoutes from './not-auth';
import { BrowserRouter } from 'react-router-dom';
import LoadingComponent from '../components/LoadingComponent';

const Navigation = () => {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<LoadingComponent/>}>
          {/* Rutas para administradores */}
          <AdminRoutes />
          {/* Rutas publicas home, visión, misión, etc */}
          <NotAuthRoutes />
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default Navigation;
