import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import AdminLayout from '../../../layout/Admin/AdminLayout';
import Container from '../../../components/Container';
import { useNavigate } from 'react-router-dom';
import config from '../../../constants/App';
import { TiPlusOutline } from "react-icons/ti";
import { useCustomToast } from '../../../hooks/useCustomToast';

const AddCoupon = () => {
    const navigate = useNavigate();
    const [category_id, setCategoryId] = useState('');
    const [supplier_id, setSupplierId] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [serial_id, setSerialId] = useState('');
    const [start_promotion, setStartPromotion] = useState('');
    const [end_promotion, setEndPromotion] = useState('');
    const [coupons_count, setCouponsCount] = useState(1);
    const [imagePreview, setImagePreview] = useState(null);
    const [categories, setCategories] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const imageRef = useRef(null);
    const { authUser } = useSelector(state => state.user);
    const { showToast } = useCustomToast();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!category_id || !supplier_id || !name.trim() || !description.trim() || !price || !serial_id.trim() || !start_promotion || !end_promotion || !coupons_count) {
            showToast({
                type: 'error',
                title: 'Error',
                body: 'Please complete all fields correctly.',
                config: { autoClose: 5000 }
            });
            return;
        }

        const formData = new FormData();
        formData.append('category_id', category_id);
        formData.append('supplier_id', supplier_id);
        formData.append('name', name);
        formData.append('description', description);
        formData.append('price', price);
        formData.append('serial_id', serial_id);
        formData.append('start_promotion', start_promotion);
        formData.append('end_promotion', end_promotion);
        formData.append('coupons_count', coupons_count);

        if (imageRef.current.files[0]) {
            formData.append('image', imageRef.current.files[0]);
        }

        try {
            await axios.post(`${config.REACT_APP_BASE_URL}/dashboard/coupons/store`, formData, {
                headers: {
                    'Authorization': `Bearer ${authUser.token}`,
                    'Content-Type': 'multipart/form-data'
                },
            });

            showToast({
                type: 'success',
                title: 'Success',
                body: 'Coupon added successfully.',
                config: { autoClose: 5000 }
            });
            navigate("/coupons");
        } catch (error) {
            // Manejo de errores aquí
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
            setImagePreview(URL.createObjectURL(file));
        } else {
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
            setImagePreview(null);
        }
    };

    useEffect(() => {
        return () => {
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
        };
    }, [imagePreview]);

    useEffect(() => {
        const fetchCategoriesAndSuppliers = async () => {
            try {
                const categoriesRes = await axios.get(`${config.REACT_APP_BASE_URL}/dashboard/coupons-categories/list`, {
                    headers: {
                        'Authorization': `Bearer ${authUser.token}`
                    }
                });
                const suppliersRes = await axios.get(`${config.REACT_APP_BASE_URL}/dashboard/suppliers/list`, {
                    headers: {
                        'Authorization': `Bearer ${authUser.token}`
                    }
                });
                setCategories(categoriesRes.data);
                setSuppliers(suppliersRes.data);
            } catch (error) {
                // Manejo de errores aquí
                console.error("Error loading categories or suppliers", error);
            }
        };

        fetchCategoriesAndSuppliers();
    }, []); // Dependencia vacía para ejecutar solo al montar

    return (
        <AdminLayout>
            <Container title="Add Coupon">
                <form onSubmit={handleSubmit} className="space-y-4" encType="multipart/form-data">
                <div>
                        <label htmlFor="category_id" className="block text-sm font-medium text-gray-700">Category</label>
                        <select
                            name="category_id"
                            id="category_id"
                            required
                            value={category_id}
                            onChange={(e) => setCategoryId(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        >
                            <option value="">Select a Category</option>
                            {categories.map((category) => (
                                <option key={category.id} value={category.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="supplier_id" className="block text-sm font-medium text-gray-700">Supplier</label>
                        <select
                            name="supplier_id"
                            id="supplier_id"
                            required
                            value={supplier_id}
                            onChange={(e) => setSupplierId(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        >
                            <option value="">Select a Supplier</option>
                            {suppliers.map((supplier) => (
                                <option key={supplier.id} value={supplier.id}>
                                    {supplier.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                    </div>
                    <div>
                        <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                        <textarea
                            name="description"
                            id="description"
                            required
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                    </div>
                    <div>
                        <label htmlFor="price" className="block text-sm font-medium text-gray-700">Price</label>
                        <input
                            type="text"
                            name="price"
                            id="price"
                            required
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                    </div>
                    <div>
                        <label htmlFor="serial_id" className="block text-sm font-medium text-gray-700">Serial ID</label>
                        <input
                            type="text"
                            name="serial_id"
                            id="serial_id"
                            required
                            value={serial_id}
                            onChange={(e) => setSerialId(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                    </div>
                    <div>
                        <label htmlFor="start_promotion" className="block text-sm font-medium text-gray-700">Start Promotion</label>
                        <input
                            type="datetime-local"
                            name="start_promotion"
                            id="start_promotion"
                            required
                            value={start_promotion}
                            onChange={(e) => setStartPromotion(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                    </div>
                    <div>
                        <label htmlFor="end_promotion" className="block text-sm font-medium text-gray-700">End Promotion</label>
                        <input
                            type="datetime-local"
                            name="end_promotion"
                            id="end_promotion"
                            required
                            value={end_promotion}
                            onChange={(e) => setEndPromotion(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                    </div>
                    <div>
                        <label htmlFor="coupons_count" className="block text-sm font-medium text-gray-700">Coupons Count</label>
                        <input
                            type="number"
                            name="coupons_count"
                            id="coupons_count"
                            required
                            value={coupons_count}
                            onChange={(e) => setCouponsCount(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                    </div>
                    <div>
                        <label htmlFor="image" className="block text-sm font-medium text-gray-700">Image</label>
                        <input
                            type="file"
                            ref={imageRef}
                            name="image"
                            id="image"
                            onChange={handleImageChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                        {imagePreview && (
                            <div className="mt-2">
                                <img src={imagePreview} alt="Image Preview" className="w-32 h-32 object-cover rounded-md" />
                            </div>
                        )}
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            type="submit"
                            className="flex items-center px-4 py-2 bg-orange-500 text-white rounded-full shadow-lg hover:bg-orange-600 transition duration-150 ease-in-out">
                            <TiPlusOutline className="mr-2" />
                            Add Coupon
                        </button>
                    </div>
                </form>
            </Container>
        </AdminLayout>
    );
};

export default AddCoupon;
