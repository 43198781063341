import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdminLayout from '../../../layout/Admin/AdminLayout';
import Container from '../../../components/Container';
import config from '../../../constants/App';
import { TiPlusOutline } from "react-icons/ti";
import { TbNavigationShare } from "react-icons/tb";

const ListNews = () => {
    const navigate = useNavigate();
    const [banners, setBanners] = useState([]);
    const { authUser } = useSelector(state => state.user);

    const fetchBanners = async () => {
        try {
            const response = await axios.get(`${config.REACT_APP_BASE_URL}/api/suppliers/banners`, {
                headers: { 'Authorization': `Bearer ${authUser.token}` },
            });
            setBanners(response.data.data);
        } catch (error) {
            console.error('Error fetching banners:', error);
        }
    };

    useEffect(() => {
        if (authUser.token) {
            fetchBanners();
        }
    }, [authUser.token]);

    const handleAddBanner = () => {
        navigate("/AddNews") // Adjust the route as needed for your application
    };

    return (
        <AdminLayout>
            <Container title="Banners" buttonActionHeader={
                <button
                    onClick={handleAddBanner}
                    className="flex items-center px-4 py-2 bg-orange-500 text-white rounded-full shadow-lg hover:bg-orange-600 transition duration-150 ease-in-out">
                    <TiPlusOutline className="text-lg" />
                    Add Banner
                </button>
            }>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {banners.map(banner => (
                        <div key={banner.id} className="p-4 border rounded-lg shadow space-y-2 bg-white">
                            <img
                                src={banner.image_url}
                                alt={`Banner ${banner.id}`}
                                className="w-full h-48 object-cover rounded-md shadow-sm"
                            />
                            <h3 className="text-lg font-semibold truncate">Banner {banner.id}</h3>
                            <p className="text-sm text-gray-600 flex items-center">
                                <TbNavigationShare className="text-lg mr-1" />
                                {banner.redirect_type}: {banner.redirect_value}
                            </p>
                        </div>
                    ))}
                </div>
            </Container>
        </AdminLayout>
    );
};

export default ListNews;