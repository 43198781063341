import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TiPlusOutline, TiCalendarOutline, TiImageOutline } from "react-icons/ti";
import AdminLayout from '../../../layout/Admin/AdminLayout';
import Container from '../../../components/Container';
import config from '../../../constants/App';
import { FaMapSigns, FaMapMarkedAlt } from "react-icons/fa";

const ListSuppliers = (props) => {
    const domain = config.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const [suppliers, setSuppliers] = useState([]);
    const { authUser } = useSelector(state => state.user);
    const [visibleSchedule, setVisibleSchedule] = useState({});
    const [visibleFacade, setVisibleFacade] = useState({});

    const fetchSuppliers = async () => {
        try {
            const response = await axios.get(`${config.REACT_APP_BASE_URL}/dashboard/suppliers/list`, {
                headers: { 'Authorization': `Bearer ${authUser.token}` },
            });
            setSuppliers(response.data);
            let scheduleVisibility = {};
            let facadeVisibility = {};
            response.data.forEach(supplier => {
                scheduleVisibility[supplier.id] = false;
                facadeVisibility[supplier.id] = false;
            });
            setVisibleSchedule(scheduleVisibility);
            setVisibleFacade(facadeVisibility);
        } catch (error) {
            console.error('Error fetching suppliers:', error);
        }
    };

    useEffect(() => {
        if (authUser.token) {
            fetchSuppliers();
        }
    }, [authUser.token]);

    const handleAddSupplier = () => {
        navigate("/AddSupplier");
    };

    const toggleScheduleVisibility = (id) => {
        setVisibleSchedule(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const toggleFacadeVisibility = (id) => {
        setVisibleFacade(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    return (
        <AdminLayout navigation={props.navigation}>
            <Container title="Suppliers" buttonActionHeader={
                <button onClick={handleAddSupplier} className="flex items-center px-4 py-2 bg-orange-500 text-white rounded-full shadow-lg hover:bg-orange-600 transition duration-150 ease-in-out">
                    <TiPlusOutline className="text-lg" />
                    Add Supplier
                </button>
            }>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {suppliers.map(supplier => (
                        <div key={supplier.id} className="p-4 border rounded-lg shadow space-y-2 bg-white">
                            <img 
                                src={`${domain}${supplier.logo}`}
                                alt={supplier.name} 
                                className="w-full h-48 object-cover rounded-md shadow-sm" 
                            />
                            <h3 className="text-lg font-semibold truncate">{supplier.name}</h3>
                            <div className="text-sm text-gray-600">
                                <div className="flex items-center mt-3">
                                    <FaMapSigns className="h-6 w-6 mr-2 text-orange-500" />
                                    <strong>Address:</strong>
                                    <p className="ml-8">{supplier.address}</p>
                                </div>
                                <div className="flex items-center mt-3">
                                    <FaMapMarkedAlt className="h-6 w-6 mr-2 text-orange-500" />
                                    <strong>Coordinates:</strong>
                                    <a href={`https://www.google.com/maps/search/?api=1&query=${supplier.gps_coordinates}`} target="_blank" rel="noopener noreferrer" className="ml-auto">
                                        <button className="px-3 py-1 border border-orange-500 text-orange-500 rounded-full hover:bg-orange-500 hover:text-white transition duration-150 ease-in-out">
                                            View Map
                                        </button>
                                    </a>
                                </div>
                                <div className="mt-3">
                                    <button onClick={() => toggleScheduleVisibility(supplier.id)} className="flex items-center font-medium border-2 border-orange-500 p-1 rounded-full">
                                        <TiCalendarOutline className="h-6 w-6 mr-2 text-orange-500" />
                                        <strong>Schedules</strong>
                                    </button>
                                    {visibleSchedule[supplier.id] && (
                                        <div className="flex flex-col gap-2 mt-2">
                                            {supplier.schedules.map((schedule, index) => (
                                                <div key={index} className="bg-orange-100 p-2 rounded-lg">
                                                    <p className="text-orange-700 font-medium">{schedule.day}</p>
                                                    <p className="text-orange-600">AM: {schedule.open_time_am} - {schedule.close_time_am}</p>
                                                    <p className="text-orange-600">PM: {schedule.open_time_pm} - {schedule.close_time_pm}</p>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <button onClick={() => toggleFacadeVisibility(supplier.id)} className="flex items-center font-medium border-2 border-orange-500 p-1 rounded-full mt-2">
                                        <TiImageOutline className="h-6 w-6 mr-2 text-orange-500" />
                                        <strong>Facade</strong>
                                    </button>
                                    {visibleFacade[supplier.id] && supplier.facade && (
                                        <div className="flex flex-col gap-2 mt-2">
                                            <img 
                                                src={`${domain}${supplier.facade}`} 
                                                alt="Facade" 
                                                className="w-full object-cover rounded-md shadow-sm"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </AdminLayout>
    );
};

export default ListSuppliers;
