import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TiPlusOutline, TiTag, TiInfoLargeOutline, TiCreditCard, TiCalendarOutline, TiTags, TiHomeOutline } from "react-icons/ti";
import AdminLayout from '../../../layout/Admin/AdminLayout';
import Container from '../../../components/Container';
import config from '../../../constants/App';

const ListCoupons = (props) => {
    const navigate = useNavigate();
    const [coupons, setCoupons] = useState([]);
    const { authUser } = useSelector(state => state.user);

    const fetchCoupons = async () => {
        try {
            const response = await axios.get(`${config.REACT_APP_BASE_URL}/dashboard/coupons/list`, {
                headers: { 'Authorization': `Bearer ${authUser.token}` },
            });
            setCoupons(response.data);
        } catch (error) {
            console.error('Error fetching coupons:', error);
        }
    };

    useEffect(() => {
        if (authUser.token) {
            fetchCoupons();
        }
    }, [authUser.token]);

    const handleAddCoupon = () => {
        navigate("/AddCoupons")
    };

    return (
        <AdminLayout navigation={props.navigation}>
            <Container title="Coupons" buttonActionHeader={
                <button
                    onClick={handleAddCoupon}
                    className="flex items-center px-4 py-2 bg-orange-500 text-white rounded-full shadow-lg hover:bg-orange-600 transition duration-150 ease-in-out">
                    <TiPlusOutline className="text-lg" />
                    Add Coupon
                </button>
            }>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {coupons.map(coupon => (
                        <div key={coupon.id} className="p-4 border rounded-lg shadow space-y-2 bg-white">
                            <img
                                src={coupon.image}
                                alt={coupon.name}
                                className="w-full h-48 object-cover rounded-md shadow-sm"
                            />
                            <h3 className="text-lg font-semibold truncate"><TiTag className="inline mr-2" />{coupon.name}</h3>
                            <p className="text-sm text-gray-600"><TiInfoLargeOutline className="inline mr-2" />{coupon.description}</p>
                            <div className="text-sm text-gray-600">
                                <p><TiCreditCard className="inline mr-2" /><strong>Price:</strong> ANG {coupon.price}</p>
                                <p><TiTags className="inline mr-2" /><strong>Category:</strong> {coupon.category_name}</p>
                                <p><TiHomeOutline className="inline mr-2" /><strong>Supplier:</strong> {coupon.supplier_name}</p>
                                <p><TiCalendarOutline className="inline mr-2" /><strong>Promotion Period:</strong> {coupon.start_promotion} to {coupon.end_promotion}</p>
                                <p><TiTag className="inline mr-2" /><strong>Available Coupons:</strong> {coupon.coupons_count}</p>
                            </div>
                            <div className="flex justify-center items-center space-x-2 mt-2">
                                <img
                                    src={coupon.qr}
                                    alt="QR Code"
                                    className="w-16 h-16 object-cover"
                                />
                                <img
                                    src={coupon.barcode}
                                    alt="Barcode"
                                    className="w-16 h-8 object-cover"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </AdminLayout>
    );
};

export default ListCoupons;
