import React from 'react';

const Container = ({ title, children, buttonActionHeader }) => {
    return (
        <div className="mx-2 p-4 mt-2 md:max-w-4xl md:mx-auto md:mt-10 bg-white shadow-lg rounded-lg">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold ml-4 text-gray-500">{title}</h2>
                {buttonActionHeader}
            </div>
            <div className='mt-10'>
                {children}
            </div>
        </div>
    );
};

export default Container;

