// src/components/CategoryCard.js
import React from 'react';

const CategoryCard = ({ title, children }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-4">
      <h3 className="text-lg font-semibold border-b pb-2 mb-2 text-orange-500">{title}</h3>
      {children}
    </div>
  );
};

export default CategoryCard;
