import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AdminLayout from '../../layout/Admin/AdminLayout';
import Container from '../../components/Container';
import BarChart from '../../components/BarChart';
import PieChartFavorites from '../../components/PieChartFavorites';
import PieChartUsed from '../../components/PieChartUsed';
import LineChart from '../../components/LineChart';
import StackedBarChart from '../../components/StackedBarChart';
import RadarChart from '../../components/RadarChart';
import axios from 'axios';
import config from '../../constants/App'; // Asegúrate de tener la configuración adecuada para las variables de entorno

const Home = (props) => {
  const [barChartData, setBarChartData] = useState(null);
  const [pieChartFavoritesData, setPieChartFavoritesData] = useState(null);
  const [pieChartUsedData, setPieChartUsedData] = useState(null);
  const [lineChartData, setLineChartData] = useState(null);
  const [stackedBarChartData, setStackedBarChartData] = useState(null);
  const [radarChartData, setRadarChartData] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { authUser } = useSelector(state => state.user); // Obtén authUser desde Redux

  const fetchGraphicsData = async () => {
    try {
        const response = await axios.get(`${config.REACT_APP_BASE_URL}/dashboard/graphics/`, {
        headers: { 'Authorization': `Bearer ${authUser.token}` },
      });
      const data = response.data;

      setBarChartData(adaptBarChartData(data.ageGenderDistribution || {}));
      setPieChartFavoritesData(adaptPieChartData(data.topUsersFavorites || []));
      setPieChartUsedData(adaptPieChartData(data.topUsersUsed || []));
      setLineChartData(adaptLineChartData(data.couponsUsageOverTime || {}));
      setStackedBarChartData(adaptStackedBarChartData(data.couponsByCategorySupplier || {}));
      setRadarChartData(adaptRadarChartData(data.userPreferences || []));
      setDataLoaded(true);
    } catch (error) {
      console.error('Error fetching graphics data:', error);
    }
  };

  useEffect(() => {
    if (authUser && authUser.token) {
      fetchGraphicsData();
    }
  }, [authUser]);

  const adaptBarChartData = (data) => {
    const maleData = data.male || {};
    const femaleData = data.female || {};

    return {
      labels: Object.keys(maleData),
      datasets: [
        {
          label: 'Male',
          data: Object.values(maleData),
          backgroundColor: 'rgba(169, 169, 169, 0.6)', // Gris claro
        },
        {
          label: 'Female',
          data: Object.values(femaleData),
          backgroundColor: 'rgba(255, 165, 0, 0.6)', // Naranja claro
        },
      ],
    };
  };

  const adaptPieChartData = (data) => {
    return {
      labels: data.length ? data.map(user => `${user.first_name} ${user.last_name}`) : [],
      datasets: [
        {
          data: data.length ? data.map(user => user.total_favorites || user.total_used) : [],
          backgroundColor: [
            'rgba(105, 105, 105, 0.6)', // Gris oscuro
            'rgba(211, 211, 211, 0.6)', // Gris claro
            'rgba(255, 228, 196, 0.6)', // Blanco
            'rgba(255, 165, 0, 0.6)', // Naranja claro
            'rgba(169, 169, 169, 0.6)', // Gris claro
            'rgba(255, 69, 0, 0.6)', // Naranja oscuro
            'rgba(245, 245, 245, 0.6)', // Blanco humo
            'rgba(255, 140, 0, 0.6)', // Naranja
          ],
        },
      ],
    };
  };

  const adaptLineChartData = (data) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
    const datasets = Object.keys(data).map(year => ({
      label: `Year ${year}`,
      data: months.map(month => data[year][month] || 0),
      borderColor: 'rgba(105, 105, 105, 1)', // Gris oscuro
      backgroundColor: 'rgba(211, 211, 211, 0.2)', // Gris claro
    }));
    return { labels: months, datasets };
  };

  const adaptStackedBarChartData = (data) => {
    const labels = Object.keys(data);
    const suppliers = Array.from(new Set(labels.flatMap(category => Object.keys(data[category]))));
    const datasets = suppliers.map(supplier => ({
      label: supplier,
      data: labels.map(category => data[category][supplier] || 0),
      backgroundColor: 'rgba(169, 169, 169, 0.6)', // Ajusta los colores según sea necesario
    }));
    return { labels, datasets };
  };

  const adaptRadarChartData = (data) => {
    if (!data.length) return { labels: [], datasets: [] };

    const labels = Object.keys(data[0] || {});
    const datasets = data.map((userData, index) => ({
      label: `User ${index + 1}`,
      data: labels.map(category => userData[category] || 0),
      backgroundColor: `rgba(${105 + index * 10}, 105, 105, 0.2)`, // Ajusta los colores según sea necesario
      borderColor: `rgba(${105 + index * 10}, 105, 105, 1)`, // Ajusta los colores según sea necesario
    }));
    return { labels, datasets };
  };

  const barChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const pieChartOptions = {
    responsive: true,
  };

  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Coupon Usage Over Time',
      },
    },
  };

  const stackedBarChartOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Coupons by Category and Supplier',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  };

  const radarChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'User Preferences in Coupon Categories',
      },
    },
  };

  const pieChartContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  };

  const pieChartStyle = {
    flex: '1 1 45%',
    margin: '10px',
  };

  return (
    <AdminLayout navigation={props.navigation}>
      <Container title={"Home"}>
        {dataLoaded ? (
          <>
            <h2 className="font-bold text-gray-700">Distribution by Sex and Age</h2>
            <BarChart data={barChartData} options={barChartOptions} />
            <div style={pieChartContainerStyle}>
              <div style={pieChartStyle}>
                <h2 className="font-bold text-gray-700">Top Users with Favorite Coupons</h2>
                <PieChartFavorites data={pieChartFavoritesData} options={pieChartOptions} />
              </div>
              <div style={pieChartStyle}>
                <h2 className="font-bold text-gray-700">Top Users with Used Coupons</h2>
                <PieChartUsed data={pieChartUsedData} options={pieChartOptions} />
              </div>
            </div>
            <h2 className="font-bold text-gray-700">Coupon Usage Over Time</h2>
            <LineChart data={lineChartData} options={lineChartOptions} />
            <h2 className="font-bold text-gray-700">Coupons by Category and Supplier</h2>
            <StackedBarChart data={stackedBarChartData} options={stackedBarChartOptions} />
            <h2 className="font-bold text-gray-700">User Preferences in Coupon Categories</h2>
            {radarChartData && radarChartData.labels.length > 0 ? (
              <RadarChart data={radarChartData} options={radarChartOptions} />
            ) : (
              <p>No data available for Radar Chart.</p>
            )}
          </>
        ) : (
          <p>Loading data...</p>
        )}
      </Container>
    </AdminLayout>
  );
};

export default Home;
