import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setAuthUser, setDataUser } from '../store/slices/user';
import HomeLayout from '../layout/Home/HomeLayout';
import { ThemeManager } from '../theme'; // Asume que themeManager está en la carpeta src/theme
import config from '../constants/App';
import LoadingComponent from '../components/LoadingComponent';
import { useCustomToast } from '../hooks/useCustomToast';
import { useNavigate } from 'react-router-dom';

const Login = (props) => {
  const navigate = useNavigate(); // Crea la instancia de navigate
  const { showToast } = useCustomToast();
  const theme = ThemeManager.getTheme(); // Obtiene el tema actual
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  // Función de utilidad para crear un retraso
  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  const handleLogin = async () => {
    setLoading(true);
    try {
      console.log(loading);
      await delay(1000);
      const apiUrl = `${config.REACT_APP_BASE_URL}/api/auth/login`;

      const loginResponse = await axios.post(apiUrl, {
        email,
        password,
        device_name: 'web',
      });

      const authToken = loginResponse.data.token;
      const dataResponse = await axios.get(`${config.REACT_APP_BASE_URL}/api/auth/data`, {
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
      });

      console.log(dataResponse)
      dispatch(setAuthUser(loginResponse.data));
      dispatch(setDataUser(dataResponse.data));

      setEmail('');
      setPassword('');

      navigate("/"); // Redirecciona a la ruta '/'
    } catch (error) {
      // Asumiendo que error.response.data.errors es el objeto de errores de Laravel
      if (error.response && error.response.data && error.response.data.errors) {
        // Muestra el toast con el componente ErrorList
        const errors = error.response.data.errors;
        showToast({
          type: 'error',
          title: 'Error Signing In:',
          // Pasa el componente ErrorList como body
          body: <ul>
          {Object.keys(errors).map((key, index) => (
            <li key={index}>
              <strong>{key}</strong>{`: ${errors[key].join(', ')}`}
            </li>
          ))}
        </ul>, 
          config: { autoClose: 8000,  }
        });

        return;
      }

      if(error.response && error.response.data && error.response.data.message){
              // Maneja otros tipos de errores o un mensaje genérico...
        showToast({
          type: 'error',
          title: error.response.data.message,
        });

        return;
      }
      // Maneja otros tipos de errores o un mensaje genérico...
      showToast({
        type: 'error',
        title: 'Error',
        body: 'An unexpected error occurred.'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('VALOR LOADING:', loading); // Esto mostrará el valor actual de `loading` cada vez que cambie
  }, [loading]);

  return (
    <HomeLayout navigation={props.navigation}>
      {loading && <LoadingComponent />}
      <div className="max-w-md mx-auto p-4 mt-10 bg-white shadow-lg rounded-lg" style={{ backgroundColor: theme.colors.background }}>
        <h1 className="text-center text-2xl font-bold mb-4" style={{ color: theme.colors.primary }}>Sign In</h1>
        <div className="space-y-6">
          <div>
            <label htmlFor="email" className="text-sm font-medium" style={{ color: theme.colors.text }}>Email Address</label>
            <input type="email" name="email" id="email" required
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1 p-3 w-full border rounded-md shadow-sm focus:outline-none"
              style={{ borderColor: theme.colors.gray, backgroundColor: theme.colors.backgroundSecondary, color: theme.colors.text, placeholderColor: theme.colors.placeholder }}
              placeholder="you@example.com" />
          </div>
          <div>
            <label htmlFor="password" className="text-sm font-medium" style={{ color: theme.colors.text }}>Password</label>
            <input type="password" name="password" id="password" required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 p-3 w-full border rounded-md shadow-sm focus:outline-none"
              style={{ borderColor: theme.colors.gray, backgroundColor: theme.colors.backgroundSecondary, color: theme.colors.text, placeholderColor: theme.colors.placeholder }}
              placeholder="********" />
          </div>
          <div>
            <button onClick={() => handleLogin()} type="submit"
              className="w-full flex justify-center py-3 px-4 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2"
              style={{ backgroundColor: theme.colors.primary, color: theme.colors.background, borderColor: theme.colors.primary, boxShadow: `0 0 4px ${theme.colors.primary}` }}>
              Sign In
            </button>
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default Login;
