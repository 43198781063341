import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import AdminLayout from '../../../layout/Admin/AdminLayout';
import Container from '../../../components/Container';
import CategoryCard from '../../../components/CategoryCard';
import Tags from '../../../components/Tags';
import config from '../../../constants/App';
import { TiPlusOutline, TiImage } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';

const ListCategories = (props) => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const { authUser } = useSelector(state => state.user);
    const domain = config.REACT_APP_BASE_URL;

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${domain}/dashboard/coupons-categories/list`, {
                headers: { 'Authorization': `Bearer ${authUser.token}` },
            });
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    useEffect(() => {
        if (authUser.token) {
            fetchCategories();
        }
    }, [authUser.token]);

    const handleAddCategory = () => {
        navigate("/AddCategory");
    };

    return (
        <AdminLayout navigation={props.navigation}>
            <Container title="Categories" buttonActionHeader={
                <button
                    onClick={handleAddCategory}
                    className="flex items-center px-4 py-2 bg-orange-500 text-white rounded-full shadow-lg hover:bg-orange-600 shadow-lg transition duration-150 ease-in-out">
                    <h2><TiPlusOutline /></h2>
                    Add category
                </button>
            }>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {categories.map(category => (
                        <CategoryCard key={category.id} title={category.name}>
                            {category.image && (
                                <div className="mb-2">
                                    <img
                                        src={`${domain}${category.image}`}
                                        alt={category.name}
                                        className="w-full h-48 object-cover rounded-md shadow-sm"
                                    />
                                </div>
                            )}
                            <Tags keywords={category.keywords} />
                        </CategoryCard>
                    ))}
                </div>
            </Container>
        </AdminLayout>
    );
};

export default ListCategories;
