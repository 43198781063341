import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai'; // Importa el ícono de carga
import { ThemeManager } from '../theme'; // Asegúrate de ajustar la ruta de importación según tu estructura de archivos

const LoadingComponent = () => {
    const theme = ThemeManager.getTheme(); // Obtiene el tema actual

    return (
        <div 
            style={{
                position: 'fixed',
                inset: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.8)', // Aumenta la opacidad a 30%
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <AiOutlineLoading3Quarters 
                className="animate-spin" 
                style={{ 
                    color: theme.colors.primary, 
                    fontSize: '4rem' // Hace el icono de carga más grande
                }} 
            />
        </div>
    );
};

export default LoadingComponent;
