import {Routes, Route } from 'react-router-dom';
import Login from '../../screens/Login';
import PublicRoute from '../redirects/PublicRoute';
import Home from '../../screens/Home';

const NotAuthRoutes = () => {
    return (
        <Routes>
          <Route path="/home" element={<PublicRoute><Home/></PublicRoute>} />
          <Route path="/login" element={<PublicRoute><Login/></PublicRoute>} />
        </Routes>
    );
  }

export default NotAuthRoutes;