import { Routes, Route } from 'react-router-dom';
import PrivateRoute from '../../redirects/PrivateRoute';
import Home from '../../../screens/admin/home';
import Home2 from '../../../screens/admin/home2';
import ListCategories from '../../../screens/admin/categories/ListCategories';
import AddCategory from '../../../screens/admin/categories/AddCategory';
import AddSupplier from '../../../screens/admin/suppliers/AddSupplier';
import ListSuppliers from '../../../screens/admin/suppliers/ListSuppliers';
import AddCoupon from '../../../screens/admin/coupons/AddCoupon';
import ListCoupons from '../../../screens/admin/coupons/ListCoupons';
import AddNews from '../../../screens/admin/news/addNews';
import ListNews from '../../../screens/admin/news/ListNews';

const AdminRoutes = () => {
    return (
        <Routes>
          {/* Home initial route */}
          <Route path="/" element={<PrivateRoute requiredRole="admin"><Home></Home></PrivateRoute>} />
          <Route path="/home2" element={<PrivateRoute requiredRole="admin"><Home2></Home2></PrivateRoute>} />

          {/* Categories  */}
          <Route path="/categories" element={<PrivateRoute requiredRole="admin"><ListCategories /></PrivateRoute>} />
          <Route path="/AddCategory" element={<PrivateRoute requiredRole="admin"><AddCategory /></PrivateRoute>} />

          {/* Suppliers  */}
          <Route path="/suppliers" element={<PrivateRoute requiredRole="admin"><ListSuppliers /></PrivateRoute>} />
          <Route path="/AddSupplier" element={<PrivateRoute requiredRole="admin"><AddSupplier /></PrivateRoute>} />

          {/* Coupons  */}
          <Route path="/coupons" element={<PrivateRoute requiredRole="admin"><ListCoupons/></PrivateRoute>} />
          <Route path="/AddCoupons" element={<PrivateRoute requiredRole="admin"><AddCoupon /></PrivateRoute>} />

          {/* Banners News  */}
          <Route path="/news" element={<PrivateRoute requiredRole="admin"><ListNews/></PrivateRoute>} />
          <Route path="/AddNews" element={<PrivateRoute requiredRole="admin"><AddNews /></PrivateRoute>} />

        </Routes>
    );
  }

export default AdminRoutes;