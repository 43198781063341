import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux'; // Asumiendo que estás utilizando Redux

const PublicRoute = ({ children }) => {
  const { isAuth } = useSelector((state) => state.user); // Accede al estado de autenticación

  return isAuth ? <Navigate to="/" replace /> : children;
};

export default PublicRoute