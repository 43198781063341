import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import AdminLayout from '../../../layout/Admin/AdminLayout';
import Container from '../../../components/Container';
import { useNavigate } from 'react-router-dom';
import config from '../../../constants/App';
import { TiPlusOutline, TiDelete } from "react-icons/ti";
import { RiAddBoxFill } from "react-icons/ri";
import { useCustomToast } from '../../../hooks/useCustomToast'; // Asegúrate de que la ruta sea correcta

const AddCategory = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [keyword, setKeyword] = useState('');
    const [keywords, setKeywords] = useState([]);
    const [imagePreview, setImagePreview] = useState(null);
    const imageRef = useRef(null);
    const { authUser } = useSelector(state => state.user);
    const { showToast } = useCustomToast();

    const addKeyword = () => {
        if (keyword && !keywords.includes(keyword)) {
            setKeywords(prev => [...prev, keyword]);
            setKeyword('');
        }
    };

    const removeKeyword = (keywordToRemove) => {
        setKeywords(keywords.filter(kw => kw !== keywordToRemove));
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
            setImagePreview(URL.createObjectURL(file));
        } else {
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
            setImagePreview(null);
        }
    };

    useEffect(() => {
        return () => {
            if (imagePreview) {
                URL.revokeObjectURL(imagePreview);
            }
        };
    }, [imagePreview]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!name.trim() || keywords.length === 0) {
            showToast({
                type: 'error',
                title: 'Error',
                body: 'Please complete all fields.',
                config: { autoClose: 5000 }
            });
            return;
        }

        const formData = new FormData();
        formData.append('name', name);
        formData.append('keywords', keywords.join(', '));
        if (imageRef.current.files[0]) {
            formData.append('image', imageRef.current.files[0]);
        }

        try {
            await axios.post(`${config.REACT_APP_BASE_URL}/dashboard/coupons-categories/store`, formData, {
                headers: {
                    'Authorization': `Bearer ${authUser.token}`,
                    'Content-Type': 'multipart/form-data'
                },
            });

            showToast({
                type: 'success',
                title: 'Success',
                body: 'Category added successfully.',
                config: { autoClose: 5000 }
            });
            navigate("/categories");
        } catch (error) {
            console.error('Error saving the category:', error);
            showToast({
                type: 'error',
                title: 'Error saving the category',
                body: 'There was an error saving the category.',
                config: { autoClose: 8000 }
            });
        }
    };

    return (
        <AdminLayout>
            <Container title="Add Category">
                <form onSubmit={handleSubmit} className="space-y-4" encType="multipart/form-data">
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Category Name</label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="mt-1 block w-full md:w-1/2 border border-gray-300 rounded-md shadow-sm p-2"
                        />
                    </div>
                    <div className="mt-4 w-full md:w-1/2">
                        <label htmlFor="keywords" className="block text-sm font-medium text-gray-700">Keywords</label>
                        <div className="flex mt-1">
                            <input
                                type="text"
                                name="keywords"
                                id="keywords"
                                value={keyword}
                                onChange={(e) => setKeyword(e.target.value)}
                                className="border border-gray-300 rounded-md shadow-sm p-2 flex-grow"
                            />
                            <button
                                type="button"
                                onClick={addKeyword}
                                className="ml-2 bg-orange-500 text-white p-2 rounded-lg">
                                <RiAddBoxFill />
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-2 mt-2">
                        {keywords.map((kw, index) => (
                            <div key={index} className="flex items-center gap-2 bg-gray-200 rounded-full px-4 py-1">
                                {kw}
                                <TiDelete className="cursor-pointer" onClick={() => removeKeyword(kw)} />
                            </div>
                        ))}
                    </div>
                    <div>
                        <label htmlFor="image" className="block text-sm font-medium text-gray-700">Image</label>
                        <input
                            type="file"
                            ref={imageRef}
                            name="image"
                            id="image"
                            onChange={handleImageChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                        {imagePreview && (
                            <div className="mt-2">
                                <img src={imagePreview} alt="Image Preview" className="w-32 h-32 object-cover rounded-md" />
                            </div>
                        )}
                    </div>
                    <div className="flex flex-col mt-4 md:flex-row md:justify-end">
                        <button
                            type="submit"
                            className="flex items-center px-4 py-2 bg-orange-500 text-white rounded-full shadow-lg hover:bg-orange-600 transition duration-150 ease-in-out justify-center w-full md:w-auto">
                            <TiPlusOutline className="mr-2" />
                            Save Category
                        </button>
                    </div>
                </form>
            </Container>
        </AdminLayout>
    );
};

export default AddCategory;
