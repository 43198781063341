import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import AdminLayout from '../../../layout/Admin/AdminLayout';
import Container from '../../../components/Container';
import { useNavigate } from 'react-router-dom';
import config from '../../../constants/App';
import { TiPlusOutline } from "react-icons/ti";
import { useCustomToast } from '../../../hooks/useCustomToast';

const AddBanner = () => {
    const navigate = useNavigate();
    const [supplierId, setSupplierId] = useState('');
    const [suppliers, setSuppliers] = useState([]);
    const [redirectType, setRedirectType] = useState('web');
    const [redirectValue, setRedirectValue] = useState('');
    const [imagePreview, setImagePreview] = useState(null);
    const [coupons, setCoupons] = useState([]);
    const [categories, setCategories] = useState([]);
    const imageRef = useRef(null);
    const { authUser } = useSelector(state => state.user);
    const { showToast } = useCustomToast();

    useEffect(() => {
        fetchSuppliers();
        fetchCategories();
    }, [authUser.token]);

    useEffect(() => {
        if (redirectType === 'supplier') {
            setRedirectValue(supplierId);
        }
    }, [redirectType, supplierId]);

    const fetchSuppliers = async () => {
        try {
            const response = await axios.get(`${config.REACT_APP_BASE_URL}/dashboard/suppliers/list`, {
                headers: {
                    'Authorization': `Bearer ${authUser.token}`
                }
            });
            setSuppliers(response.data);
        } catch (error) {
            console.error("Error loading suppliers", error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${config.REACT_APP_BASE_URL}/dashboard/coupons-categories/list`, {
                headers: {
                    'Authorization': `Bearer ${authUser.token}`
                }
            });
            setCategories(response.data);
        } catch (error) {
            console.error("Error loading categories", error);
        }
    };

    const handleSupplierChange = async (e) => {
        const supplierId = e.target.value;
        setSupplierId(supplierId);
        setRedirectValue(''); // Reset redirectValue to ensure it's updated appropriately

        try {
            const response = await axios.get(`${config.REACT_APP_BASE_URL}/dashboard/suppliers/${supplierId}/coupons`, {
                headers: {
                    'Authorization': `Bearer ${authUser.token}`
                }
            });
            setCoupons(response.data);
        } catch (error) {
            console.error("Error loading coupons", error);
            setCoupons([]);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!redirectValue) {
            showToast({
                type: 'error',
                title: 'Error',
                body: 'Please enter a redirect value.',
                config: { autoClose: 5000 }
            });
            return;
        }

        const formData = new FormData();
        formData.append('supplier_id', supplierId);
        formData.append('redirect_type', redirectType);
        formData.append('redirect_value', redirectValue);

        if (imageRef.current.files[0]) {
            formData.append('image', imageRef.current.files[0]);
        }

        // Display the key/value pairs
        for (var pair of formData.entries()) {
            console.log(pair[0]+ ', ' + pair[1]); 
        }

        try {
            await axios.post(`${config.REACT_APP_BASE_URL}/api/suppliers/banner`, formData, {
                headers: {
                    'Authorization': `Bearer ${authUser.token}`,
                    'Content-Type': 'multipart/form-data'
                },
            });

            showToast({
                type: 'success',
                title: 'Success',
                body: 'Banner added successfully.',
                config: { autoClose: 5000 }
            });
            navigate("/news");
        } catch (error) {
            showToast({
                type: 'error',
                title: 'Error on upload',
                body: 'Failed to upload banner.',
                config: { autoClose: 5000 }
            });
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImagePreview(URL.createObjectURL(file));
        } else {
            setImagePreview(null);
        }
    };

    const renderRedirectValueInput = () => {
        switch (redirectType) {
            case 'web':
                return <input
                    type="text"
                    name="redirect_value"
                    id="redirect_value"
                    placeholder="Insert URL"
                    required
                    value={redirectValue}
                    onChange={(e) => setRedirectValue(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />;
            case 'screen':
                return <select
                    name="redirect_value"
                    id="redirect_value"
                    required
                    value={redirectValue}
                    onChange={(e) => setRedirectValue(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                >
                    <option value="">Select Screen</option>
                    <option value="Home">Home</option>
                    <option value="Offers">Offers</option>
                    <option value="Map">Map</option>
                    <option value="FAQ">FAQ</option>
                    <option value="Favorites">Favorites</option>
                </select>;
            case 'coupon':
                return <select
                    name="redirect_value"
                    id="redirect_value"
                    required
                    value={redirectValue}
                    onChange={(e) => setRedirectValue(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                    disabled={coupons.length === 0}
                >
                    <option value="">Select a Coupon</option>
                    {coupons.map((coupon) => (
                        <option key={coupon.id} value={coupon.id}>
                            {coupon.name}
                        </option>
                    ))}
                </select>;
            case 'category':
                return <select
                    name="redirect_value"
                    id="redirect_value"
                    required
                    value={redirectValue}
                    onChange={(e) => setRedirectValue(e.target.value)}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                >
                    <option value="">Select a Category</option>
                    {categories.map((category) => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </select>;
            case 'supplier':
                // Automatically use the selected supplier ID
                return <input
                    type="text"
                    name="redirect_value"
                    id="redirect_value"
                    value={supplierId ? `Supplier ID: ${supplierId}` : 'Select a Supplier'}
                    readOnly
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />;
            default:
                return null;
        }
    };

    return (
        <AdminLayout>
            <Container title="Add Banner">
                <form onSubmit={handleSubmit} className="space-y-4" encType="multipart/form-data">
                    <div>
                        <label htmlFor="supplier_id" className="block text-sm font-medium text-gray-700">Supplier</label>
                        <select
                            name="supplier_id"
                            id="supplier_id"
                            required
                            value={supplierId}
                            onChange={handleSupplierChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        >
                            <option value="">Select a Supplier</option>
                            {suppliers.map((supplier) => (
                                <option key={supplier.id} value={supplier.id}>
                                    {supplier.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="redirect_type" className="block text-sm font-medium text-gray-700">Redirect Type</label>
                        <select
                            name="redirect_type"
                            id="redirect_type"
                            required
                            value={redirectType}
                            onChange={(e) => {
                                setRedirectType(e.target.value);
                                setRedirectValue(''); // Reset redirectValue when type changes
                            }}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        >
                            <option value="web">Web</option>
                            <option value="screen">Screen</option>
                            <option value="coupon">Coupon</option>
                            <option value="category">Category</option>
                            <option value="supplier">Supplier</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="redirect_value" className="block text-sm font-medium text-gray-700">Redirect Value</label>
                        {renderRedirectValueInput()}
                    </div>
                    <div>
                        <label htmlFor="image" className="block text-sm font-medium text-gray-700">Image</label>
                        <input
                            type="file"
                            ref={imageRef}
                            name="image"
                            id="image"
                            onChange={handleImageChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                        {imagePreview && (
                            <div className="mt-2">
                                <img src={imagePreview} alt="Image Preview" className="w-32 h-32 object-cover rounded-md" />
                            </div>
                        )}
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            type="submit"
                            className="flex items-center px-4 py-2 bg-orange-500 text-white rounded-full shadow-lg hover:bg-orange-600 transition duration-150 ease-in-out">
                            <TiPlusOutline className="mr-2" />
                            Add Banner
                        </button>
                    </div>
                </form>
            </Container>
        </AdminLayout>
    );
};

export default AddBanner;