import React from 'react';

const Tags = ({ keywords }) => {
  // Divide las keywords por comas y elimina espacios en blanco extra
  const tags = keywords.split(',').map(tag => tag.trim());

  return (
    <div className="flex flex-wrap gap-2">
      {tags.map((tag, index) => (
        <span key={index} className="px-3 py-1 text-sm text-gray-600 border border-gray-400 rounded-full shadow">
          {tag}
        </span>
      ))}
    </div>
  );
};

export default Tags;
