import { FaHome, FaUsers, FaTools, FaBlog, FaInfo, FaEnvelope, FaSignInAlt } from 'react-icons/fa';

export const sessionLinks = (isAuth) => {
  return isAuth
    ? { url: "/logout", text: "Logout", styles: "px-4 py-2 leading-none border rounded text-orange-500 border-orange-500 hover:border-transparent hover:text-white hover:bg-orange-500", Icon: FaSignInAlt }
    : { url: "/login", text: "Login", styles: "px-4 py-2 leading-none border rounded text-orange-500 border-orange-500 hover:border-transparent hover:text-white hover:bg-orange-500", Icon: FaSignInAlt };
};

export const mainLinks = [
  { url: "/", text: "Home", styles: "text-orange-500 hover:text-orange-600", Icon: FaHome },
  { url: "#team", text: "Team", styles: "text-gray-500 hover:text-orange-600", Icon: FaUsers },
  { url: "#features", text: "Features", styles: "text-gray-500 hover:text-orange-600", Icon: FaTools },
  { url: "#blog", text: "Blog", styles: "text-gray-500 hover:text-orange-600", Icon: FaBlog },
  { url: "#about", text: "About", styles: "text-gray-500 hover:text-orange-600", Icon: FaInfo },
  { url: "#contact", text: "Contact", styles: "text-gray-500 hover:text-orange-600", Icon: FaEnvelope },
];