import { FaHome, FaSignInAlt } from 'react-icons/fa';
import { SiAmazonredshift } from "react-icons/si";
import { FaNewspaper } from "react-icons/fa";
import { RiCoupon3Fill } from "react-icons/ri";
import { MdAddHomeWork } from "react-icons/md";
import { MdOutlineListAlt } from "react-icons/md";

export const sessionLinks = (isAuth) => {
  return isAuth
    ? { url: "/logout", text: "Logout", styles: "px-4 py-2 leading-none border rounded text-orange-500 border-orange-500 hover:border-transparent hover:text-white hover:bg-orange-500", Icon: FaSignInAlt }
    : { url: "/login", text: "Login", styles: "px-4 py-2 leading-none border rounded text-orange-500 border-orange-500 hover:border-transparent hover:text-white hover:bg-orange-500", Icon: FaSignInAlt };
};

export const mainLinks = [
  { url: "/", text: "Home", styles: "text-orange-500 hover:text-orange-600", Icon: FaHome },
  { url: "/categories", text: "Categories", styles: "text-orange-500 hover:text-orange-600", Icon: MdOutlineListAlt },
  { url: "/suppliers", text: "Suppliers", styles: "text-orange-500 hover:text-orange-600", Icon: MdAddHomeWork },
  { url: "/coupons", text: "Coupons", styles: "text-orange-500 hover:text-orange-600", Icon: RiCoupon3Fill },
  { url: "/news", text: "Banners", styles: "text-orange-500 hover:text-orange-600", Icon: FaNewspaper },
];