import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children, requiredRole }) => {
  const { isAuth, dataUser } = useSelector((state) => state.user);
  const initialScreen = "/home"
  // Verifica si el usuario está autenticado y tiene el rol requerido
  const isAuthenticatedAndHasRequiredRole = isAuth && dataUser && dataUser.role === requiredRole;
  return isAuthenticatedAndHasRequiredRole ? children : <Navigate to={initialScreen} replace />;
};

export default PrivateRoute;
