import { toast, Bounce } from 'react-toastify';
// Importa los íconos que quieras usar de react-icons
import { FaBeer, FaInfoCircle, FaExclamationTriangle, FaCheckCircle } from 'react-icons/fa';
import { MdOutlineReportGmailerrorred } from "react-icons/md";

import React from 'react'; // Importante para poder usar JSX

export const useCustomToast = () => {

  const getCustomIcon = (type) => {
    let IconComponent;
    switch (type) {
        case 'success':
            IconComponent = FaCheckCircle;
            break;
        case 'info':
            IconComponent = FaInfoCircle;
            break;
        case 'warning':
            IconComponent = FaExclamationTriangle;
            break;
        case 'error':
            IconComponent = MdOutlineReportGmailerrorred;
            break;
        default:
            IconComponent = FaBeer; // Un ícono genérico para otros casos, si los hay
    }
    return <IconComponent className="custom-toast-icon" />;
};

  const showToast = ({ type = 'info', title, body, toastId = `toast-${Date.now()}`, config = {} }) => {
    const defaultConfig = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
      transition: Bounce,
      icon: getCustomIcon(type), // Usa el icono personalizado
      ...config,
    };

    if (title || body) {
      toast[type](
        <div>
          {title && <p className="font-bold">{title}</p>}
          {body && <div>{body}</div>}
        </div>,
        { ...defaultConfig, toastId }
      );
    }
  };

  return { showToast };
};
