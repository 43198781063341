import React, { useState } from 'react';
import Modal from 'react-modal';
import { FaTag, FaTools, FaPaintBrush, FaFileAlt, FaGlobeAmericas, FaExpand, FaArrowLeft, FaArrowRight, FaBars, FaTimes, FaApple, FaAndroid, FaFacebookF, FaTwitter, FaGooglePlusG, FaInstagram, FaPlay } from 'react-icons/fa';
import mobileAppIcon from '../../assets/images/icons/banner2.png';
import logoApp from '../../assets/images/icons/logo_app.png';
import phoneImage from '../../assets/images/icons/3.png';
import img5 from '../../assets/images/icons/5.jpg';
import img6 from '../../assets/images/icons/6.jpeg';
import img7 from '../../assets/images/icons/7.jpeg';
import user1 from '../../assets/images/icons/user1.jpg';
import user2 from '../../assets/images/icons/user2.jpg';
import user3 from '../../assets/images/icons/user3.jpg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

Modal.setAppElement('#root');

const LandingLayout = (props) => {
    const commonBg = "bg-gradient-to-r from-orange-500 via-orange-600 to-orange-700";
    const mainLinks = [
        { label: "Home", url: "#" },
        { label: "Features", url: "#" },
        { label: "Pricing", url: "#" },
        { label: "Download Now", url: "#", special: true }
    ];

    const Header = () => {
        const [isMenuOpen, setIsMenuOpen] = useState(false);

        const toggleMenu = () => {
            setIsMenuOpen(prevState => !prevState);
        };

        return (
            <header className="bg-gradient-to-r from-orange-500 via-orange-600 to-orange-700 p-4 text-white">
                <div className="container mx-auto flex flex-col sm:flex-row justify-between items-center">
                    <div className="flex items-center justify-between w-full sm:w-auto relative">
                        <button onClick={toggleMenu} className="text-2xl sm:hidden">
                            {isMenuOpen ? <FaTimes /> : <FaBars />}
                        </button>
                        <div className="bg-white rounded-lg shadow-md mt-2 lg:mt-0 p-1">
                            <img src={"https://res.cloudinary.com/dqirirwxk/image/upload/v1718170494/logo_app.e22988aa64f77ce45239_xyomid.png"} alt="AWESOME APP" className="h-8 sm:h-12 mx-auto" />
                        </div>
                    </div>
                    <nav className={`${isMenuOpen ? 'block' : 'hidden'} sm:flex sm:items-center`}>
                        <ul className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mt-4 sm:mt-0">
                            {mainLinks.map((link, index) => (
                                <li key={index} className="text-center">
                                    <a href={link.url} className={`hover:text-gray-200 transition ${link.special ? 'font-semibold border border-white shadow-lg px-4 py-2 rounded-full' : ''}`}>
                                        {link.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </header>
        );
    };

    const ParallaxSection = () => {
        return (
            <section className="parallax bg-fixed bg-center bg-cover h-96 flex items-center justify-center text-white text-center p-4" style={{ backgroundImage: 'url(https://res.cloudinary.com/dqirirwxk/image/upload/v1718166922/DALL_E_2024-06-11_23.34.56_-_A_modern_gym_setting_with_various_fitness_equipment_such_as_treadmills_dumbbells_and_weight_machines._The_gym_is_empty_with_no_people_present._The_mdp8j0.webp)' }}>
                <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
                    <div className="flex-1 mt-20">
                        <h1 className="text-4xl md:text-6xl text-center md:text-left">Lorem Ipsum</h1>
                        <div className="w-full flex justify-center md:justify-start mt-4">
                            <button className="bg-white text-red-500 font-bold py-2 px-4 rounded hover:bg-gray-200 transition duration-200">
                                Explorare Incepit
                            </button>
                        </div>
                    </div>
                    <div className="mt-8 md:mt-16">
                        <img src={"https://res.cloudinary.com/dqirirwxk/image/upload/v1718169091/banner2.bf17a0688323a4b57eb6_vkrjfe.png"} alt="Mobile App" className=" h-44 md:h-80" />
                    </div>
                </div>
            </section>
        );
    };

    const Feature = ({ Icon, title, description }) => {
        return (
            <div className="flex flex-col items-center p-4 md:w-1/3">
                <Icon className="text-3xl mb-2 text-orange-500" />
                <h3 className="font-semibold text-lg text-gray-700">{title}</h3>
                <p className="text-center text-gray-600">{description}</p>
            </div>
        );
    };

    const VideoModal = () => {
        const [modalIsOpen, setModalIsOpen] = useState(false);

        const openModal = () => setModalIsOpen(true);
        const closeModal = () => setModalIsOpen(false);

        return (
            <div>
                <button onClick={openModal} className="flex items-center mt-4 text-white hover:text-gray-300 transition duration-200">
                    <FaPlay className="mr-2 text-2xl" />
                    PLAY VIDEO
                </button>
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Video Modal"
                    className="modal-content"
                    overlayClassName="modal-overlay"
                >
                    <div className="bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-4xl mx-auto">
                        <div className="flex justify-end p-2">
                            <button onClick={closeModal} className="text-gray-600 hover:text-gray-900">
                                <FaTimes className="text-2xl" />
                            </button>
                        </div>
                        <div className="p-4">
                            <iframe
                                width="100%"
                                height="500"
                                src="https://res.cloudinary.com/dqirirwxk/video/upload/v1718165317/Home_workout_with_Bfox_fitness_1_qfi19a.mp4"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    };

    const VideoSection = () => {
        return (
            <section className="relative bg-fixed bg-center bg-cover h-96" style={{ backgroundImage: 'url(https://res.cloudinary.com/dqirirwxk/image/upload/v1718166922/DALL_E_2024-06-11_23.34.56_-_A_modern_gym_setting_with_various_fitness_equipment_such_as_treadmills_dumbbells_and_weight_machines._The_gym_is_empty_with_no_people_present._The_mdp8j0.webp)' }}>
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="text-center text-white p-8">
                        <h2 className="text-3xl font-semibold mb-4">SOFT & DURABLE LEGGINGS</h2>
                        <p className="mb-4">Pellentesque ultricies convallis leo, sit amet rhoncus efficitur et.</p>
                        <button className="mt-4 bg-red-600 text-white font-bold py-2 px-4 rounded hover:bg-red-700 transition duration-200">
                            Gear Up
                        </button>
                        <VideoModal />
                    </div>
                </div>
            </section>
        );
    };

    const FeaturesSection = () => {
        return (
            <section className="bg-white py-12">
                <div className="container mx-auto px-4 flex flex-wrap justify-between lg:px-20 xl:px-20">
                    <Feature
                        Icon={FaTag}
                        title="Titulus I"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    />
                    <Feature
                        Icon={FaTools}
                        title="Titulus II"
                        description="Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    />
                    <Feature
                        Icon={FaPaintBrush}
                        title="Titulus III"
                        description="Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                    />
                    <Feature
                        Icon={FaFileAlt}
                        title="Titulus IV"
                        description="Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
                    />
                    <Feature
                        Icon={FaGlobeAmericas}
                        title="Titulus V"
                        description="Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                    />
                    <Feature
                        Icon={FaExpand}
                        title="Titulus VI"
                        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                    />
                </div>
                <div className="flex justify-center mt-4">
                    <VideoModal />
                </div>
            </section>
        );
    };

    const AboutAppSection = () => {
        return (
            <section className="bg-white py-12">
                <div className="container mx-auto flex flex-wrap items-center justify-between px-4 lg:px-20 xl:px-20">
                    <div className="w-full md:w-1/2 flex justify-center">
                        <img src={"https://res.cloudinary.com/dqirirwxk/image/upload/v1718205638/BFOX_HEALTH_SLIDER_BANNER_20240612_111650_0000_jbo05n.png"} alt="Smartphone with App" className="w-3/4 h-auto max-w-md md:max-w-lg lg:max-w-xl" />
                    </div>
                    <div className="w-full md:w-1/2 px-6">
                        <h2 className="text-2xl font-semibold mb-4 text-gray-600">Quid est Lorem Ipsum?</h2>
                        <p className="mb-4 text-gray-500">
                            Adde parvam fabulam de app tua hic, quod propositum tuum app solvit et cur putes utentem volere eam in machina sua habere.
                        </p>
                        <p className="mb-4 text-gray-500">
                            Principale casum usus app tuae restitue infra enumerans. Certifica ut congruentem screenshot in laeva parte ponas.
                        </p>
                        <ul className="list-disc list-inside space-y-2 text-gray-500">
                            <li>Design incredibile ad app tuam praesentandum</li>
                            <li>Plene Responsivum et a Bootstrap & PaperKit potentium</li>
                            <li>Facillimum ad Customizandum et Constituendum</li>
                        </ul>
                    </div>
                </div>
            </section>
        );
    };     

    const screenshots = [
        "https://res.cloudinary.com/dqirirwxk/image/upload/v1718205527/WhatsApp_Image_2024-06-12_at_7.54.46_AM_f9yxrw.jpg", 
        "https://res.cloudinary.com/dqirirwxk/image/upload/v1718205527/WhatsApp_Image_2024-06-12_at_7.54.01_AM_asw5ny.jpg", 
        "https://res.cloudinary.com/dqirirwxk/image/upload/v1718205527/WhatsApp_Image_2024-06-12_at_7.55.24_AM_qhjlgw.jpg"
    ];
    
    const ScreenshotSlider = () => {
        const [current, setCurrent] = useState(0);
        const totalScreenshots = screenshots.length;
    
        const nextSlide = () => {
            let nextIndex = current === totalScreenshots - 1 ? 0 : current + 1;
            setCurrent(nextIndex);
        };
    
        const prevSlide = () => {
            let prevIndex = current === 0 ? totalScreenshots - 1 : current - 1;
            setCurrent(prevIndex);
        };
    
        return (
            <section className="bg-white py-12">
                <div className="container mx-auto px-4 flex items-center justify-center lg:px-20 xl:px-20 relative">
                    <FaArrowLeft
                        onClick={prevSlide}
                        className="cursor-pointer text-orange-500 text-4xl absolute left-0 z-10"
                    />
                    <div className="w-full h-[32rem] bg-gray-200 flex items-center justify-center relative overflow-hidden">
                        {screenshots.map((screenshot, index) => (
                            <div
                                key={index}
                                className={`absolute inset-0 transition-opacity duration-500 ease-in-out ${index === current ? 'opacity-100' : 'opacity-0'}`}
                                style={{ backgroundImage: `url(${screenshot})`, backgroundSize: 'cover', backgroundPosition: 'top' }}
                            >
                            </div>
                        ))}
                    </div>
                    <FaArrowRight
                        onClick={nextSlide}
                        className="cursor-pointer text-orange-500 text-4xl absolute right-0 z-10"
                    />
                </div>
            </section>
        );
    };
    

    const testimonials = [
        {
            name: 'Patricius Harrius',
            position: 'CEO, Mirabile Inc.',
            message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non possum imaginari operari in meis projectis sine ea. Multas horas temporis mei servavit et productivitatem meam auxit.',
            image: "https://res.cloudinary.com/dqirirwxk/image/upload/v1718206123/Captura_de_pantalla_2024-06-12_a_las_10.28.07_a._m._ctqlt3.png"
        },
        {
            name: 'Iohanna Lawson',
            position: 'Design, Mirabile Inc.',
            message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non possum imaginari operari in meis projectis sine ea. Multas horas temporis mei servavit et productivitatem meam auxit.',
            image: "https://res.cloudinary.com/dqirirwxk/image/upload/v1718206124/Captura_de_pantalla_2024-06-12_a_las_10.28.21_a._m._cajyz7.png"
        },
        {
            name: 'Robin Barnes',
            position: 'Developer, Mirabile Inc.',
            message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non possum imaginari operari in meis projectis sine ea. Multas horas temporis mei servavit et productivitatem meam auxit.',
            image: "https://res.cloudinary.com/dqirirwxk/image/upload/v1718206124/Captura_de_pantalla_2024-06-12_a_las_10.28.14_a._m._mmzab6.png"
        }
    ];

    const TestimonialsSection = () => {
        return (
            <div className="bg-white py-12">
                <div className="container mx-auto px-4 lg:px-20 xl:px-20">
                    <h2 className="text-center text-3xl font-semibold mb-10 text-gray-600">Quid dicunt nostri clientes</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="bg-gray-100 p-6 rounded-lg shadow-lg">
                                <div className="flex flex-col items-center text-center">
                                    <img className="w-24 h-24 rounded-full mb-4" src={testimonial.image} alt={testimonial.name} />
                                    <h3 className="text-lg font-semibold">{testimonial.name}</h3>
                                    <p className="text-sm text-gray-600 mb-4">{testimonial.position}</p>
                                    <p className="text-gray-700">{testimonial.message}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const downloads = [
        {
            platform: 'Apple App Store',
            icon: <FaApple className="text-blue-500 text-3xl" />,
            link: '#'
        },
        {
            platform: 'Google Play Store',
            icon: <FaAndroid className="text-green-500 text-3xl" />,
            link: '#'
        }
    ];

    const DownloadSection = () => {
        return (
            <div className="bg-orange-500 py-10">
                <div className="container mx-auto px-4 text-white text-center">
                    <h2 className="text-2xl font-bold mb-6">Download Lorem Ipsum App Nunc</h2>
                    <div className="flex justify-center gap-4">
                        {downloads.map((download, index) => (
                            <a key={index} href={download.link} className="bg-white rounded-lg px-6 py-3 flex items-center gap-3 shadow-md hover:bg-gray-100 transition-colors">
                                {download.icon}
                                <span className="font-medium text-gray-800 text-sm">{download.platform}</span>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        );
    };

    const Footer = () => {
        return (
            <footer className="bg-gray-900 text-white py-8">
                <div className="container mx-auto px-4">
                    <div className="flex flex-col items-center">
                        <div className="flex justify-center mb-4">
                            <a href="#" className="mx-2 text-gray-400 hover:text-white"><FaFacebookF /></a>
                            <a href="#" className="mx-2 text-gray-400 hover:text-white"><FaTwitter /></a>
                            <a href="#" className="mx-2 text-gray-400 hover:text-white"><FaGooglePlusG /></a>
                            <a href="#" className="mx-2 text-gray-400 hover:text-white"><FaInstagram /></a>
                        </div>
                        <div className="text-center text-sm text-gray-400">
                            Copyright © Lorem Ipsum App. Omnia jura reservata.<br />
                        </div>
                    </div>
                </div>
            </footer>
        );
    };

    const products = [
        { image: "https://res.cloudinary.com/dqirirwxk/image/upload/v1718162384/product3_lcvfkq.jpg", name: 'Sports Wear Set for Women', price: '$35.00' },
        { image: "https://res.cloudinary.com/dqirirwxk/image/upload/v1718162383/product1_jgf4dt.jpg", name: 'High Waist Yoga Pants', price: '$35.00' },
        { image: "https://res.cloudinary.com/dqirirwxk/image/upload/v1718162384/product4_tovwob.jpg", name: 'Women Sports Short With Tops', price: '$35.00' },
        { image: "https://res.cloudinary.com/dqirirwxk/image/upload/v1718162383/product2_dqw1ib.jpg", name: 'Padded Sports Bra for Gym', price: '$35.00', soldOut: true },
    ];

    const ProductsCarousel = () => {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        return (
            <section className="bg-white py-12">
                <div className="container mx-auto px-4 lg:px-20 xl:px-20">
                    <h2 className="text-center text-3xl font-semibold mb-10 text-gray-600">Our Products</h2>
                    <Slider {...settings}>
                        {products.map((product, index) => (
                            <div key={index} className="p-4">
                                <div className="bg-gray-100 p-6 rounded-lg shadow-lg text-center relative">
                                    {product.soldOut && (
                                        <div className="absolute top-2 right-2 bg-red-600 text-white text-xs font-bold py-1 px-2 rounded-full">SOLD OUT</div>
                                    )}
                                    <img src={product.image} alt={product.name} className="w-full h-48 object-cover mb-4" />
                                    <h3 className="text-lg font-semibold">{product.name}</h3>
                                    <p className="text-gray-600">{product.price}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        );
    };

    return (
        <div className="min-h-screen">
            <Header />
            <ParallaxSection />
            <FeaturesSection />
            <VideoSection />
            <AboutAppSection />
            <ScreenshotSlider />
            <TestimonialsSection />
            <ProductsCarousel />
            <DownloadSection />
            <Footer />
            {props.children}
        </div>
    );
};

export default LandingLayout;
