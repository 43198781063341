import React from 'react';
import AdminLayout from '../../layout/Admin/AdminLayout';
import Container from '../../components/Container';
import BarChart from '../../components/BarChart';
import PieChartFavorites from '../../components/PieChartFavorites';
import PieChartUsed from '../../components/PieChartUsed';
import LineChart from '../../components/LineChart';
import StackedBarChart from '../../components/StackedBarChart';
import RadarChart from '../../components/RadarChart';

const Home = (props) => {
  const barChartData = {
    labels: ['18-25', '26-35', '36-45', '46-55', '56-65', '65+'],
    datasets: [
      {
        label: 'Male',
        data: [18, 30, 15, 20, 10, 8], // More test data for males
        backgroundColor: 'rgba(169, 169, 169, 0.6)', // Gris claro
      },
      {
        label: 'Female',
        data: [20, 25, 18, 15, 12, 6], // More test data for females
        backgroundColor: 'rgba(255, 165, 0, 0.6)', // Naranja claro
      },
    ],
  };

  const barChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const pieChartFavoritesData = {
    labels: ['Juan Perez', 'Maria Gomez', 'Luis Martinez', 'Ana Lopez', 'Carlos Sanchez', 'Lucia Ramirez', 'David Torres', 'Isabel Gutierrez'],
    datasets: [
      {
        label: 'Top Users with Favorite Coupons',
        data: [7, 8, 5, 10, 6, 4, 9, 3], // More test data for favorite coupons
        backgroundColor: [
          'rgba(105, 105, 105, 0.6)', // Gris oscuro
          'rgba(211, 211, 211, 0.6)', // Gris claro
          'rgba(255, 228, 196, 0.6)', // Blanco
          'rgba(255, 165, 0, 0.6)', // Naranja claro
          'rgba(169, 169, 169, 0.6)', // Gris claro
          'rgba(255, 69, 0, 0.6)', // Naranja oscuro
          'rgba(245, 245, 245, 0.6)', // Blanco humo
          'rgba(255, 140, 0, 0.6)', // Naranja
        ],
      },
    ],
  };

  const pieChartUsedData = {
    labels: ['Juan Perez', 'Maria Gomez', 'Luis Martinez', 'Ana Lopez', 'Carlos Sanchez', 'Lucia Ramirez', 'David Torres', 'Isabel Gutierrez'],
    datasets: [
      {
        label: 'Top Users with Used Coupons',
        data: [10, 8, 7, 12, 5, 4, 9, 6], // More test data for used coupons
        backgroundColor: [
          'rgba(105, 105, 105, 0.6)', // Gris oscuro
          'rgba(211, 211, 211, 0.6)', // Gris claro
          'rgba(255, 228, 196, 0.6)', // Blanco
          'rgba(255, 165, 0, 0.6)', // Naranja claro
          'rgba(169, 169, 169, 0.6)', // Gris claro
          'rgba(255, 69, 0, 0.6)', // Naranja oscuro
          'rgba(245, 245, 245, 0.6)', // Blanco humo
          'rgba(255, 140, 0, 0.6)', // Naranja
        ],
      },
    ],
  };

  const pieChartOptions = {
    responsive: true,
  };

  const lineChartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Coupons Used',
        data: [30, 45, 28, 50, 75, 60, 90], // Test data
        borderColor: 'rgba(105, 105, 105, 1)', // Gris oscuro
        backgroundColor: 'rgba(211, 211, 211, 0.2)', // Gris claro
      },
    ],
  };

  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Coupon Usage Over Time',
      },
    },
  };

  const stackedBarChartData = {
    labels: ['Vitamins and Supplements', 'Food and Drinks', 'Apparel', 'Appliances', 'Fitness Equipment'],
    datasets: [
      {
        label: 'Supplier 1',
        data: [50, 30, 20, 10],
        backgroundColor: 'rgba(105, 105, 105, 0.6)', // Gris oscuro
      },
      {
        label: 'Supplier 2',
        data: [40, 20, 30, 25],
        backgroundColor: 'rgba(211, 211, 211, 0.6)', // Gris claro
      },
      {
        label: 'Supplier 3',
        data: [20, 25, 40, 35],
        backgroundColor: 'rgba(255, 165, 0, 0.6)', // Naranja claro
      },
    ],
  };

  const stackedBarChartOptions = {
    plugins: {
      title: {
        display: true,
        text: 'Coupons by Category and Supplier',
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  };

  const radarChartData = {
    labels: ['Vitamins and Supplements', 'Food and Drinks', 'Apparel', 'Appliances', 'Fitness Equipment'],
    datasets: [
      {
        label: 'User 1',
        data: [65, 59, 90, 81, 56],
        backgroundColor: 'rgba(105, 105, 105, 0.2)', // Gris oscuro
        borderColor: 'rgba(105, 105, 105, 1)', // Gris oscuro
      },
      {
        label: 'User 2',
        data: [28, 48, 40, 19, 96],
        backgroundColor: 'rgba(255, 165, 0, 0.2)', // Naranja claro
        borderColor: 'rgba(255, 165, 0, 1)', // Naranja claro
      },
    ],
  };

  const radarChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'User Preferences in Coupon Categories',
      },
    },
  };

  const pieChartContainerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  };

  const pieChartStyle = {
    flex: '1 1 45%',
    margin: '10px',
  };

  return (
    <AdminLayout navigation={props.navigation}>
      <Container title={"Home"}>
        <h2 className="font-bold text-gray-700">Distribution by Sex and Age</h2>
        <BarChart data={barChartData} options={barChartOptions} />
        <div style={pieChartContainerStyle}>
          <div style={pieChartStyle}>
            <h2 className="font-bold text-gray-700">Top Users with Favorite Coupons</h2>
            <PieChartFavorites data={pieChartFavoritesData} options={pieChartOptions} />
          </div>
          <div style={pieChartStyle}>
            <h2 className="font-bold text-gray-700">Top Users with Used Coupons</h2>
            <PieChartUsed data={pieChartUsedData} options={pieChartOptions} />
          </div>
        </div>
        <h2 className="font-bold text-gray-700">Coupon Usage Over Time</h2>
        <LineChart data={lineChartData} options={lineChartOptions} />
        <h2 className="font-bold text-gray-700">Coupons by Category and Supplier</h2>
        <StackedBarChart data={stackedBarChartData} options={stackedBarChartOptions} />
        <h2 className="font-bold text-gray-700">User Preferences in Coupon Categories</h2>
        <RadarChart data={radarChartData} options={radarChartOptions} />        <div>{"asdasdasdasd"}</div>
      </Container>
    </AdminLayout>
  );
};

export default Home;
