import React from 'react';
import { useSelector } from 'react-redux';
import { sessionLinks, mainLinks } from './linksData'; // Importando los enlaces desde el archivo linksData.js
import Header from '../../components/Header';

const AdminLayout = (props) => {
  const isAuth = useSelector((state) => state.user.isAuth);
  const session = sessionLinks(isAuth);

  const links = [...mainLinks, session];

  return (
    <div className="min-h-screen bg-gray-600 !important">
        <Header links={links} />
        {props.children}
    </div>
  );
};

export default AdminLayout;