import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../store/slices/user';
import { useNavigate } from 'react-router-dom';

const Header = ({ links }) => {
    const navigate = useNavigate(); // Crea la instancia de navigate
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const dispatch = useDispatch();

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleLogout = (url) => {
        if (url === "/logout") {
            navigate('/login')
            dispatch(logoutUser());
        } else {
            // Ejecutar la función de navegación aquí
            // Supongo que tienes algún enrutador configurado
            // y una función navigate disponible
            if (url !== "/logout")
            {
                navigate(url);
            }
        }
    };

    return (
        <header className='shadow-md py-4 px-4 sm:px-10 bg-white font-sans min-h-[70px]'>
            <div className='container mx-auto flex flex-wrap items-center justify-between'>
                <a href="#!" className='flex items-center'>
                    <img src="https://res.cloudinary.com/dqirirwxk/image/upload/v1710964426/rk2bpyi7shqgplukdrvf.png" alt="Logo" className='w-28' />
                </a>
                {/* Botón de menú para dispositivos móviles */}
                <button onClick={toggleSidebar} className='lg:hidden ml-7'>
                    <svg className="w-7 h-7" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                    </svg>
                </button>

                {/* Navegación principal para pantallas grandes */}
                <div className='hidden lg:block'>
                    <nav className='flex justify-end items-center'>
                        <ul className='flex flex-row space-x-8 mt-3 lg:mt-0'>
                            {links.map((link, index) => (
                                <li key={index}>
                                    <button onClick={() => handleLogout(link.url)} className={`block py-2 ${link.styles}`}>
                                        {/* Renderiza el ícono correspondiente */}
                                        {link.Icon && <link.Icon className="inline-block w-5 h-5 mr-2" />}
                                        {link.text}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </div>

                {/* Sidebar para dispositivos móviles */}
                <div className={`fixed inset-0 z-50 lg:hidden transition-transform transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} bg-white shadow-lg`}>
                    <div className='flex justify-between items-center p-4'>
                        <span>Menu</span>
                        <button onClick={toggleSidebar}>
                            <svg className="w-6 h-6" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </div>
                    <nav className='flex flex-col items-center'>
                        {links.map((link, index) => (
                            <button onClick={() => handleLogout(link.url)} className={`py-2 ${link.styles}`} key={index}>
                                {/* Renderiza el ícono correspondiente */}
                                {link.Icon && <link.Icon className="inline-block w-5 h-5 mr-2" />}
                                {link.text}
                            </button>
                        ))}
                    </nav>
                </div>
                <div className={`fixed inset-0 bg-black opacity-50 z-40 ${isSidebarOpen ? "block" : "hidden"}`} onClick={toggleSidebar}></div>
            </div>
        </header>
    );
};

export default Header;
