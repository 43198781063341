import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import AdminLayout from '../../../layout/Admin/AdminLayout';
import Container from '../../../components/Container';
import { useNavigate } from 'react-router-dom';
import config from '../../../constants/App';
import { TiPlusOutline, TiDeleteOutline } from "react-icons/ti";
import { useCustomToast } from '../../../hooks/useCustomToast';

const AddSupplier = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [gpsCoordinates, setGpsCoordinates] = useState('');
    const [schedules, setSchedules] = useState([{ day: '', open_time_am: '', close_time_am: '', open_time_pm: '', close_time_pm: '' }]);
    const [logoPreview, setLogoPreview] = useState(null);
    const [facadePreview, setFacadePreview] = useState(null);
    const logoRef = useRef(null);
    const facadeRef = useRef(null);
    const { authUser } = useSelector(state => state.user);
    const { showToast } = useCustomToast();
    const allDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

    const selectedDays = schedules.map(schedule => schedule.day);

    const getAvailableDaysForIndex = (currentIndex) => {
        return allDays.filter(day =>
            !selectedDays.includes(day) || day === schedules[currentIndex].day
        );
    };

    const handleScheduleChange = (index, field, value) => {
        const newSchedules = [...schedules];
        newSchedules[index][field] = value;
        setSchedules(newSchedules);
    };

    const addSchedule = () => {
        setSchedules([...schedules, { day: '', open_time_am: '', close_time_am: '', open_time_pm: '', close_time_pm: '' }]);
    };

    const removeSchedule = (indexToRemove) => {
        setSchedules(schedules.filter((_, index) => index !== indexToRemove));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('address', address);
        formData.append('gps_coordinates', gpsCoordinates);

        if (logoRef.current.files[0]) {
            formData.append('logo', logoRef.current.files[0]);
        }

        if (facadeRef.current.files[0]) {
            formData.append('facade', facadeRef.current.files[0]);
        }

        schedules.forEach((schedule, index) => {
            formData.append(`schedules[${index}][day]`, schedule.day);
            formData.append(`schedules[${index}][open_time_am]`, schedule.open_time_am);
            formData.append(`schedules[${index}][close_time_am]`, schedule.close_time_am);
            formData.append(`schedules[${index}][open_time_pm]`, schedule.open_time_pm);
            formData.append(`schedules[${index}][close_time_pm]`, schedule.close_time_pm);
        });

        try {
            const response = await axios.post(`${config.REACT_APP_BASE_URL}/dashboard/suppliers/store`, formData, {
                headers: {
                    'Authorization': `Bearer ${authUser.token}`,
                    'Content-Type': 'multipart/form-data'
                },
            });

            showToast({
                type: 'success',
                title: 'Success',
                body: 'Supplier added successfully.',
                config: { autoClose: 5000 }
            });
            navigate("/suppliers");
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                const errors = error.response.data.errors;
                showToast({
                    type: 'error',
                    title: 'Error Signing In:',
                    body: <ul>{Object.keys(errors).map((key, index) => (
                        <li key={index}>
                            <strong>{key}</strong>{`: ${errors[key].join(', ')}`}
                        </li>
                    ))}</ul>,
                    config: { autoClose: 8000 }
                });
                return;
            }

            if (error.response && error.response.data && error.response.data.message) {
                showToast({
                    type: 'error',
                    title: error.response.data.message,
                });
                return;
            }

            showToast({
                type: 'error',
                title: 'Error',
                body: 'An unexpected error occurred.'
            });
        }
    };

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (logoPreview) {
                URL.revokeObjectURL(logoPreview);
            }
            setLogoPreview(URL.createObjectURL(file));
        } else {
            if (logoPreview) {
                URL.revokeObjectURL(logoPreview);
            }
            setLogoPreview(null);
        }
    };

    const handleFacadeChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (facadePreview) {
                URL.revokeObjectURL(facadePreview);
            }
            setFacadePreview(URL.createObjectURL(file));
        } else {
            if (facadePreview) {
                URL.revokeObjectURL(facadePreview);
            }
            setFacadePreview(null);
        }
    };

    useEffect(() => {
        return () => {
            if (logoPreview) {
                URL.revokeObjectURL(logoPreview);
            }
            if (facadePreview) {
                URL.revokeObjectURL(facadePreview);
            }
        };
    }, [logoPreview, facadePreview]);

    return (
        <AdminLayout>
            <Container title="Add Supplier">
                <form onSubmit={handleSubmit} className="space-y-4" encType="multipart/form-data">
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Supplier Name</label>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                    </div>
                    <div>
                        <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label>
                        <textarea
                            name="address"
                            id="address"
                            required
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                    </div>
                    <div>
                        <label htmlFor="gpsCoordinates" className="block text-sm font-medium text-gray-700">GPS Coordinates</label>
                        <input
                            type="text"
                            name="gpsCoordinates"
                            id="gpsCoordinates"
                            required
                            value={gpsCoordinates}
                            onChange={(e) => setGpsCoordinates(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                    </div>
                    <div>
                        <label htmlFor="logo" className="block text-sm font-medium text-gray-700">Logo</label>
                        <input
                            type="file"
                            ref={logoRef}
                            name="logo"
                            id="logo"
                            onChange={handleLogoChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                        {logoPreview && (
                            <div className="mt-2">
                                <img src={logoPreview} alt="Logo Preview" className="w-32 h-32 object-cover rounded-md" />
                            </div>
                        )}
                    </div>
                    <div>
                        <label htmlFor="facade" className="block text-sm font-medium text-gray-700">Facade</label>
                        <input
                            type="file"
                            ref={facadeRef}
                            name="facade"
                            id="facade"
                            onChange={handleFacadeChange}
                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        />
                        {facadePreview && (
                            <div className="mt-2">
                                <img src={facadePreview} alt="Facade Preview" className="w-32 h-32 object-cover rounded-md" />
                            </div>
                        )}
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Schedules</label>
                        {schedules.map((schedule, index) => (
                            <div key={index} className="flex flex-wrap gap-3 mb-4">
                                <select
                                    value={schedule.day}
                                    onChange={(e) => handleScheduleChange(index, 'day', e.target.value)}
                                    className="border border-gray-300 rounded-md shadow-sm p-2 flex-grow w-full lg:w-64">
                                    <option value="">Select Day</option>
                                    {getAvailableDaysForIndex(index).map(day => (
                                        <option key={day} value={day}>{day}</option>
                                    ))}
                                </select>
                                <input
                                    type="time"
                                    value={schedule.open_time_am}
                                    onChange={(e) => handleScheduleChange(index, 'open_time_am', e.target.value)}
                                    placeholder="AM Open"
                                    className="border border-gray-300 rounded-md shadow-sm p-2"
                                />
                                <input
                                    type="time"
                                    value={schedule.close_time_am}
                                    onChange={(e) => handleScheduleChange(index, 'close_time_am', e.target.value)}
                                    placeholder="AM Close"
                                    className="border border-gray-300 rounded-md shadow-sm p-2"
                                />
                                <div className='mt-2'>-</div>
                                <input
                                    type="time"
                                    value={schedule.open_time_pm}
                                    onChange={(e) => handleScheduleChange(index, 'open_time_pm', e.target.value)}
                                    placeholder="PM Open"
                                    className="border border-gray-300 rounded-md shadow-sm p-2"
                                />
                                <input
                                    type="time"
                                    value={schedule.close_time_pm}
                                    onChange={(e) => handleScheduleChange(index, 'close_time_pm', e.target.value)}
                                    placeholder="PM Close"
                                    className="border border-gray-300 rounded-md shadow-sm p-2"
                                />
                                <TiDeleteOutline className="cursor-pointer" onClick={() => removeSchedule(index)} />
                            </div>
                        ))}
                        <button type="button" onClick={addSchedule} className="text-sm text-orange-500 border-2 border-orange-500 shadow p-1 rounded-lg">Add Schedule</button>
                    </div>
                    <div className="flex justify-end mt-4">
                        <button
                            type="submit"
                            className="flex items-center px-4 py-2 bg-orange-500 text-white rounded-full shadow-lg hover:bg-orange-600 transition duration-150 ease-in-out">
                            <TiPlusOutline className="mr-2" />
                            Save Supplier
                        </button>
                    </div>
                </form>
            </Container>
        </AdminLayout>
    );
};

export default AddSupplier;
